<template>
    <div class="m-2">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />
        <v-row style="direction:ltr">
            <v-col
            cols="12"
            md="5"
            sm="12">
            <b-input-group class="mt-3">
                <b-form-input placeholder="Start Date" style="background:#eee" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" style="background:#eee" type="date" v-model="edate"></b-form-input>
                <b-input-group-append style="border-left:1px solid #CCC">
                    <b-button variant="light" style="background: darkblue !important;font-size:14px;color:#FFF;padding:7px 22px;border-radius:5px" @click="getReport()">بحث</b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col cols="12" md="7" sm="12" class="text-right">
                <b-button variant="light" style="width:145px;margin-right:9px;display:none" class="mytab mytab1 mt-3" @click="exportE('e')">تصدير اكسل</b-button>
                <b-button variant="light" style="width:145px;margin-right:3px;display:none;background:red;color:#FFF" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
            </v-col>
        </v-row>
        <v-row style="directirtlon:">
            
            <v-col cols="12" md="12" sm="12">
                <v-simple-table>
                    <thead>

                        <tr>
                            <th class="text-center">اسم الموظف</th>
                            <th class="text-center">رقم الجوال</th>
                            <th class="text-center">عدد البطاقات</th>
                            <th class="text-center">قيمة شغل اليد</th>
                            <th class="text-center">الضريبة</th>
                            <th class="text-center">المجموع بدون ضريبة</th>
                            <th class="text-center">النسبة %</th>
                            <th class="text-center">مبلغ النسبة</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,ind) in percRows" :key="ind">
                            <td class="text-center">{{ item.company }}</td>
                            <td class="text-center">{{ item.agent_name }}</td>
                            <td class="text-center">{{ item.agent_mobile }}</td>
                            <td class="text-center">{{ item.invoice_count }}</td>
                            <td class="text-center">{{ item.deviese_price }}</td>
                            <td class="text-center">{{ item.license_price }}</td>
                            <td class="text-center">{{ item.license_vat }}</td>
                            <td class="text-center">{{ item.license_total }}</td>
                            <td class="text-center">{{ item.agent_percent }}</td>
                        </tr>
                    </tbody>
                    <!-- <tfoot>
                        <tr style="background:#f39292">
                            <th class="text-center"></th>
                            <th class="text-center">المجموع</th>
                            <th class="text-center">{{ d_in.total }}</th>
                            <th class="text-center">{{ d_in.vat }}</th>
                            <th class="text-center">{{ d_in.ftotal }}</th>
                        </tr>
                    </tfoot> -->
                </v-simple-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from 'axios'
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
export default{
    components: {BreadCrumbs,TabsComp},
    data() 
        {
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            modal1: false,
            active_tab:4,
            percRows: [],
            items:[
                // {
                //     text: this.$store.state.hometitle,
                //     disabled: false,
                //     to: '/home',
                // },
                // {
                //     text: 'التقارير',
                //     disabled: true,
                //     to: '/reports',
                // },
                {
                    text: 'تقرير النسب',
                    disabled: true,
                    to: '/reports/percent-reports',
                },
                
            ],
            agents: [],
            companies:[],
            sdate:'',
            edate: ''
        }
    },
    methods: {
        getReport() {
            const post = new FormData();
            post.append("type","percentRep");
            post.append("auth" , this.$cookies.get(this.$COOKIEPhase));
            post.append("data[sdate]" , this.sdate);
            post.append("data[edate]" , this.edate);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data.results.data;
                this.percRows = res.byagent;
                this.companies = res.bycompany;
            })
        },
        
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    
        tabs: function() {
            let tabs =  [
                {
                    index:3,name:'تقرير الضريبة',href:'/reports/vat-reports',class:'mytab4'
                },
                {
                    index:0,name:'تقرير اليومية',href:'/reports/daily-reports',class:'mytab1'
                }
            ];
            if(this.$store.state.licenseType.cars){
                tabs.push(
                    {
                        index:1,name:'تقرير البطاقات',href:'/reports/cards-reports',class:'mytab2'
                    }
                );
            }
            tabs.push(
                {
                    index:2,name:'تقرير المبيعات',href:'/reports/sales-reports',class:'mytab3'
                }
            );
            tabs.push({
                    index:5,name:'مرتجع المبيعات',href:'/reports/return-reports',class:'mytab6'
                }
            );
            tabs.push({
                    index:4,name:'تقرير النسب',href:'/reports/percent-reports',class:'mytab'
                }
            );
            return tabs;
        }
    },
    created() {
        this.getReport()
    },
   
}
</script>